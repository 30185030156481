@charset "UTF-8";
/***  IMPORT   ***/
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

strong {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.kv-container--croisiere {
  line-height: 1; }
  .kv-container--croisiere div, .kv-container--croisiere span, .kv-container--croisiere iframe, .kv-container--croisiere p, .kv-container--croisiere a, .kv-container--croisiere img, .kv-container--croisiere sup, .kv-container--croisiere ul, .kv-container--croisiere li, .kv-container--croisiere footer, .kv-container--croisiere nav {
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0; }
  .kv-container--croisiere footer {
    display: block; }
  .kv-container--croisiere ul {
    list-style: none; }

/******************************************************************************************/
/******   SPRITES    *********/
/******************************************************************************************/
/******   COLORS    *********/
/*****************************************************************/
/******   LAYOUT    *********/
/******    HEADER    ******/
/******    NAVIGATION    ******/
/******    FOOOTER    ******/
/*****************************************************************/
/******   HOME AND COMMON    *********/
/******    MOTEUR    ******/
/****** SLIDER ************/
/****** DATEPICKER ************/
/****** NOUVELLE RECHERCHE ************/
/****** INFORMATION DESTI ************/
/****** TOP HOTEL ************/
/******    TOP DESTI    ******/
/****** AUTRE VOYAGE ************/
/****** TOUR OPERATOR ************/
/******    DERNIERE MINUTES    ******/
/******   BONS PLANS   ******/
/******   BLOCK TEL   ******/
/******   RECOMMANDATION   ******/
/******   CATEGORIE INFO   ******/
/******   TOP CATEGORIE   ******/
/*****************************************************************/
/******   FICHE PRODUIT     *********/
/******   BLOCK RECAP    ******/
/******* BLOCK MULTI ********/
/******* CALENDRIER ********/
/******* CONDITION ********/
/******* DESCRIPTION ********/
/******* AVIS CLIENT ********/
/******* PRODUIT SIMILAIRE ********/
/******* LIEN FP ********/
/*****************************************************************/
/******   SEJLIST     *********/
/******   AFFINAGE    ******/
/******   ENTETE SL    ******/
/******   ONGLET    ******/
/******   PAGINATION    ******/
/******   INFO SEJOUR    ******/
/******   GUIDE    ******/
/*****  BLOCK PRODUIT   *****/
/*****  ORDRE RESULTAT   *****/
/*****  PLUS IDEE SEJOUR   *****/
/***** CLASSE EXTEND ********/
/***** BREADCRUMB ********/
/***** TRIPADVISOR ********/
/***** PUB ********/
/***** ADSENSE ********/
/***** COOKIE ********/
/***** CROSSLIEN ********/
/*****************************************************************/
/******   PAGE MERCH     *********/
/*****************************************************************/
/******   SEJOUR     ****************************/
/*****************************************************************/
/******   INFORMATIONS    *********/
/*****************************************************************/
/******   SLIDER AGENCE    *********/
/*****************************************************************/
/******   MOYEN DE PAIEMENT    *********/
/*****************************************************************/
/******   POPUP AGENCE    *********/
/******   VACANCES A LA UNE AGENCE    *********/
/******************************************************************************************************/
/************      A SUPPRIMER une fois toute les pages specifiee comme ci-dessus     ********************/
/******************************************************************************************************/
/******************************************************************************************/
/******   Config Html    *********/
html, body {
  width: 100%; }

/******************************************************************************************/
/******   SPRITES    *********/
/******************************************************************************************/
/******   IMAGES    *********/
/******************************************************************************************/
/******   COLORS    *********/
/*****************************************************************/
/******   GENERAL    *********/
/*****************************************************************/
/******   LAYOUT    *********/
/******    HEADER    ******/
/******    BOTTOM HEADER    ******/
/******    NAVIGATION    ******/
/******    NAVIGATION > SUBMENU    ******/
/******    FOOOTER Refonte    ******/
/******    FOOOTER > INFORMATION COMPLEMENTAIRE    ******/
/*$color8*/
/*$color1;*/
/*$color10*/
/*****************************************************************/
/******   HOME AND COMMON    *********/
/******    MOTEUR    ******/
/****** SLIDER ************/
/****** DATEPICKER ************/
/****** NOUVELLE RECHERCHE ************/
/****** INFORMATION DESTI ************/
/****** AUTO PROMO ************/
/******   INSCRIPTION NEWSLETTER    *********/
/******   INSCRIPTION NEWSLETTER CRM   *********/
/******   INSCRIPTION NEWSLETTER POPUP  REFONTE *********/
/****** TOP HOTEL ************/
/******    TOP DESTI    ******/
/****** TOUR OPERATOR ************/
/****** AUTRE VOYAGE ************/
/******    DERNIERE MINUTES    ******/
/******   BONS PLANS   ******/
/******   ASSURANCE   ******/
/******   BLOCK TEL   ******/
/******   BLOCK RESERVATION AGENCE   ******/
/******   RECOMMANDATION   ******/
/******   INSPIRATION   ******/
/******   CATEGORIE INFO   ******/
/******   TOP CATEGORIE   ******/
/******   VOYAGE DE GROUPES    *********/
/******   BROCHURES FRAM    *********/
/*****************************************************************/
/******   HOME THEMATIQUE     *********/
/*****************************************************************/
/******   FICHE PRODUIT     *********/
/******   BLOCK RECAP    ******/
/******* BLOCK MULTI ********/
/******* CALENDRIER ********/
/******* CONDITION ********/
/******* DESCRIPTION ********/
/******* AVIS CLIENT ********/
/******* PRODUIT SIMILAIRE ********/
/******* LIEN FP ********/
/******* LIEN FP ********/
/*****************************************************************/
/******   SEJLIST     *********/
/***** OVERLAY ********/
/***** LOADER ********/
/******   MOTEUR    ******/
/******   AFFINAGE    ******/
/******   ENTETE SL    ******/
/******   ONGLET    ******/
/******   PAGINATION    ******/
/******   INFO SEJOUR    ******/
/******   GUIDE    ******/
/*****  BLOCK PRODUIT   *****/
/* [X] */
/* [X] */
/* [X] */
/* [X] */
/* [X] */
/*****  ORDRE RESULTAT   *****/
/*****  COUP DE COEUR   *****/
/*****  PLUS IDEE SEJOUR   *****/
/*****  AUCUN RESULTAT   *****/
/***** CLASSE EXTEND ********/
/***** BREADCRUMB ********/
/***** TRIPADVISOR ********/
/***** PUB ********/
/***** ADSENSE ********/
/***** COOKIE ********/
/***** CROSSLIEN ********/
/*****************************************************************/
/******   PAGE MERCH     *********/
/*****************************************************************/
/******   SEJOUR     ****************************/
/*****************************************************************/
/******   INFORMATIONS    *********/
/*****************************************************************/
/******   ERROR    *********/
/*****************************************************************/
/******   SLIDER AGENCE    *********/
/*****************************************************************/
/******   MOYEN DE PAIEMENT    *********/
/*****************************************************************/
/******   POPUP AGENCE    *********/
/*****************************************************************/
/******   DETAIL AGENCE    *********/
/******   Gmap AGENCE    *********/
/******   VACANCES A LA UNE AGENCE    *********/
/*************************************************************************************************************************/
/******   PROSPECTING ZONE  (Bloc contenant les 3 blocs voyage en groupe / newsletter / Tripdadvisor)  *********/
/***** TripAdvisor (HP) ********/
/*****************************************************************/
/******   PAGE OP    *********/
/*****************************************************************/
/******   PAGE OT    *********/
/******   NEWSLETTER DELAY   *********/
/*****************************************************************/
/****** MESSAGE PERSONNALISE *********/
/*****************************************************************/
/****** MESSAGE POP IN CALENDRIER FP *********/
/******************************************************************************************************/
/************      A SUPPRIMER une fois toute les pages specifiee comme ci-dessus     ********************/
/******************************************************************************************************/
/************* Mixin pour le box-shadow ***************************************
	$inset peut �tre � true ou false, mais false est la valeur par defaut
*******************************************************************************/
/*********** Mixin ************************************************************************************************************
	$font-size : Pour la taille de la police (Ex. 12px), 
  	$line-height : Pour la hauteur de ligne qui ne doit pas avoir d'unite (Ex. 1, ou encore 1.2,... et NON 10px, 12px ,...) 
    $lines-to-show : Pour le nombre de ligne qu'on souhaite afficher (Ex. 1, 2,...), 
    $max-width : Pour la largeur maximale sur lequel devra s'afficher notre element (Ex. 212px)
*******************************************************************************************************************************/
@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?pqdxk1');
  src: url('../../fonts/icomoon.eot?pqdxk1#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf?pqdxk1') format('truetype'), url('../../fonts/icomoon.woff?pqdxk1') format('woff'), url('../../fonts/icomoon.svg?pqdxk1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

#information-complementaire {
  position: relative;
  background: #f4F6F6;
  clear: both;
  float: none;
  width: 100%; }
  #information-complementaire *, #information-complementaire *:before, #information-complementaire *:after {
    box-sizing: border-box; }
  #information-complementaire .content {
    min-width: 1240px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 16px 0 18px;
    text-align: center; }
  #information-complementaire .column-container {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-content: center; }
  #information-complementaire .column {
    /*width: 20%;*/
    width: 212px;
    display: block; }
    #information-complementaire .column + .column {
      margin: 0 0 0 54px; }
    #information-complementaire .column:nth-child(4) {
      width: 214px;
      margin: 0 0 0 34px; }
    #information-complementaire .column p.subtitle {
      font-size: 15px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      width: 100%;
      margin: 0 auto 6px; }
    #information-complementaire .column p {
      font-family: arial;
      font-size: 11px;
      color: #27343e;
      margin: 0; }
  #information-complementaire .title-container {
    width: 100%;
    margin: 0 0 10px 0;
    text-align: center; }
    #information-complementaire .title-container h2 {
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #27343e;
      text-transform: uppercase; }

.smile, .padlock, .headphones, .world, .marker {
  display: block;
  width: 100%;
  margin: 0 0 11px 0; }
  .smile:before, .padlock:before, .headphones:before, .world:before, .marker:before {
    color: #e2290d;
    /*font-size:;*/
    width: 47px;
    height: 47px;
    display: inline-block;
    font-family: 'icomoon' !important;
    font-size: 45px; }

.smile:before {
  content: "\e90c"; }

.padlock:before {
  content: "\e90b"; }

.headphones:before {
  content: "\e90d"; }

.world:before {
  content: "\e904"; }

.marker:before {
  content: "\e90e"; }

footer {
  font-size: 16px;
  background: #393939;
  width: 100%;
  min-width: 1240px;
  padding: 2.875rem 0 1.526rem 0;
  font-family: 'RobotoCondensed-Bold', sans-serif; }
  footer *, footer *:before, footer *:after {
    box-sizing: border-box; }
  footer .content {
    padding: 0;
    width: 100%;
    min-width: 1240px;
    max-width: 1280px;
    margin: 0 auto; }
  footer .column-container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    min-width: 1240px;
    margin: 0 auto;
    justify-content: space-between; }
  footer .column {
    width: 230px; }
    footer .column .logo {
      width: 144px;
      height: auto; }
    footer .column > p, footer .column .encart-tel > p, footer .column #frm_refsearch > p {
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      text-transform: uppercase; }
    footer .column + .column {
      width: 660px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      footer .column + .column + .column {
        width: 270px;
        justify-content: flex-start;
        align-content: start; }
        footer .column + .column + .column p {
          margin: 0 0 0.625rem 0; }
    footer .column .inscription-newsletter {
      width: 100%; }
    footer .column .serviceClient, footer .column .infoPratique {
      width: 36%; }
      footer .column .serviceClient p, footer .column .infoPratique p {
        font-size: 16px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        color: #d4d2d3;
        margin: 0 0 9px 0;
        text-transform: uppercase; }
    footer .column .infosReservation {
      width: 28%; }
      footer .column .infosReservation p {
        width: 100%; }
      footer .column .infosReservation #bloc-tel-footer > .numero {
        margin: 0 0 6px 0;
        font-size: 16px;
        color: #fff;
        -ms-color: #fff !important; }
      footer .column .infosReservation #bloc-tel-footer > .detail, footer .column .infosReservation #bloc-tel-footer > .sup {
        font-size: 11px;
        margin: 0;
        text-transform: none; }
  footer ul {
    display: inline-block;
    font-family: arial;
    font-size: 12px;
    color: #fff; }
    footer ul a, footer ul span {
      text-decoration: none;
      font-family: arial;
      font-size: 12px;
      color: #fff;
      line-height: 25px;
      cursor: pointer; }
      footer ul a:hover, footer ul span:hover {
        color: #e2290d;
        text-decoration: underline; }
  footer ul.with-bullet li:before {
    content: ">";
    font-size: 12px;
    color: #e2290d;
    margin-right: 5px; }
  footer ul.social {
    margin: 0 0 1.875rem 0; }
    footer ul.social li {
      display: inline-block;
      vertical-align: top;
      position: relative; }
      footer ul.social li + li {
        margin-left: 0.25rem; }
    footer ul.social a, footer ul.social [data-olf] {
      display: inline-block;
      vertical-align: top;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 67px;
      -moz-border-radius: 67px;
      -ms-border-radius: 67px;
      border-radius: 67px;
      width: 35px;
      height: 35px;
      background-color: #fff; }
      footer ul.social a:hover, footer ul.social [data-olf]:hover {
        background-color: #e2290d;
        transition: all .2s ease; }
        footer ul.social a:hover span:before, footer ul.social [data-olf]:hover span:before {
          color: #fff; }
  footer ul.typePaiement {
    margin: 0 0 1.5rem 0; }
    footer ul.typePaiement li {
      display: inline-flex;
      justify-content: flex-start;
      align-content: center;
      width: 46px;
      height: 31px;
      background: #fff;
      border-radius: 5px; }
      footer ul.typePaiement li + li {
        margin: 0 0 0 4px; }
      footer ul.typePaiement li span {
        display: block;
        width: 100%;
        background: url(../../img/common/sprite-payment.png) no-repeat;
        cursor: default; }
        footer ul.typePaiement li span:before {
          display: block;
          width: 100%;
          height: auto; }
      footer ul.typePaiement li .cb {
        background-position: 3px 2px; }
      footer ul.typePaiement li .mastercard {
        background-position: -37px 1px; }
      footer ul.typePaiement li .americanexpress {
        background-position: -163px 1px; }
      footer ul.typePaiement li .ancv {
        background-position: -119px 1px; }
      footer ul.typePaiement li .visa {
        background-position: -79px 1px; }
  footer #frm_refsearch {
    width: 100%;
    flex-flow: wrap; }
    footer #frm_refsearch p {
      margin: 0 0 0.625rem 0; }
    footer #frm_refsearch input#refSai {
      /*width: 205px;*/
      width: 74%;
      max-width: 205px;
      height: 2.375rem;
      border: 1px solid #888888;
      background-color: #fff;
      font-family: arial;
      font-size: 11px;
      font-style: normal4;
      color: #808080;
      padding: 0 0 0 9px; }
    footer #frm_refsearch .submit {
      width: 3.0625rem;
      height: 2.375rem;
      border: 2px solid #d4d2d3;
      border-radius: 3px;
      background-color: #393939;
      font-size: 0.875rem;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      text-transform: uppercase;
      margin: 0 0 0 8px;
      padding: 0;
      font-style: normal;
      cursor: pointer; }
      footer #frm_refsearch .submit:hover {
        text-decoration: none;
        color: #393939;
        background: #fff;
        border: 0px solid #393939;
        transition: all .2s ease;
        border-radius: 3px; }
  footer p.brand {
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 700;
    color: #fff;
    border-bottom: none;
    margin: 0.875rem 0 0 0;
    cursor: default;
    line-height: 24px; }
  footer .mention {
    margin: 1.25rem auto 0;
    padding-top: 2rem;
    border-top: 1px dotted #787474;
    max-width: 1280px;
    text-align: center; }
    footer .mention p, footer .mention a {
      font-family: arial;
      font-size: 0.625rem;
      color: #fff;
      -ms-color: #fff !important; }
    footer .mention .ttc {
      margin-bottom: 0.625rem; }
    footer .mention .canal {
      margin-top: 0.9375rem; }

.facebook, .blog, .youtube, .twitter, .instagram, .pinterest {
  color: #333;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 18px;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.facebook:before {
  content: "\e922"; }

.twitter:before {
  content: "\e923"; }

.youtube:before {
  content: "\e927"; }

.blog:before {
  content: "\e902"; }

.pinterest {
  left: 8px; }
  .pinterest:before {
    content: "\e926"; }

.instagram:before {
  content: "\e903"; }

/*.encart-tel { margin-top: 25px; }*/
#bloc-tel-footer {
  color: #fff; }
  #bloc-tel-footer .numero, #bloc-tel-footer a {
    font-family: arial;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.625rem;
    color: #fff; }
  #bloc-tel-footer .numeron {
    margin: 0 0 5px 0; }
  #bloc-tel-footer .detail {
    font-family: arial;
    font-size: 9px;
    line-height: 9px;
    color: #fff; }
  #bloc-tel-footer sup {
    display: block;
    font-family: arial;
    font-size: 9px;
    line-height: 1.0625rem; }

footer #inscription-newsletter-footer {
  width: 100%;
  background: none;
  margin: 0 0 1.25rem 0; }
  footer #inscription-newsletter-footer .kv-NewsletterCRM {
    text-align: left; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM p.title {
      width: 100%;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      text-align: left;
      color: #d4d2d3;
      padding: 0;
      margin: 0 0 7px 0; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM p.title:before {
        content: none; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM .kv-NewsletterCRM-mandatoriesFields {
      text-align: right;
      color: #fff;
      font-family: arial;
      font-size: 9px;
      width: 260px;
      display: inline-block; }
  footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription {
    display: flex;
    justify-content: flex-start;
    flex-flow: nowrap;
    width: 100%; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-email, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      width: 276px !important;
      min-width: 261px; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-email.error .kv-TextInput:after {
      color: #e2290d; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      background: #fff;
      height: 2.375rem;
      border: 1px solid #888888;
      display: flex;
      align-items: center; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput {
      position: relative;
      width: 100%; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput:after {
        content: "\e90f";
        display: block;
        width: 19px;
        height: 16px;
        position: absolute;
        top: 0.625rem;
        right: 13px;
        font-family: icomoon !important;
        font-size: 1.0625rem;
        color: #808080; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input {
        width: 100%;
        height: 2.375rem;
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        padding: 0 0.625rem; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input.kv-NewsletterCRM--error {
          border: 1px solid #e2290d; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input::placeholder {
          font-style: italic; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage {
        width: 276px;
        color: #fff;
        background: #e2290d;
        font-size: 13px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-style: normal;
        bottom: -52px;
        left: 0;
        padding: 12px 0 0 0;
        text-align: center; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage:before {
          display: none; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage:after {
          border-top: 9px solid transparent;
          border-bottom: 9px solid #e52f00;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          top: -21px; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      margin: 0 0 0 9px; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput {
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        width: 100%;
        position: relative; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-label {
        width: 100%;
        height: 2.375rem;
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        padding: 0 0.625rem;
        display: inline-flex;
        align-items: center; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-label::after {
          position: absolute;
          right: 0.625rem;
          top: 16px;
          height: 0;
          width: 0;
          border-top: 5px solid #808080;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 38px;
        border: none; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-SelectInput-title {
          font-family: arial;
          font-size: 0.75rem;
          font-weight: bold;
          color: #e2290d;
          display: block; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul {
          width: 100%; }
          footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul li {
            font-family: arial;
            font-size: 0.75rem;
            color: #808080;
            padding: 0 0 0 0.75rem; }
            footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul li:hover {
              /*background: $footer-input-hover;*/ }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more {
          color: #e2290d; }
          footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more:before {
            color: #e2290d; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--less {
          font-family: arial;
          font-size: 0.75rem;
          color: #e2290d;
          text-decoration: underline; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-sendData {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 126px !important;
      height: 2.375rem;
      border: 2px solid #d4d2d3;
      background: #393939;
      font-size: 0.875rem;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      margin: 0 0 0 9px;
      border-radius: 3px; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-sendData:hover {
        text-decoration: none;
        color: #393939;
        background: #fff;
        border: 0px solid #393939;
        transition: all .2s ease;
        border-radius: 3px; }
